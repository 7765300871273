.borrow-pg {
  background-color: #323232;
  position: absolute;
  top:0;
  left:0;
}
.borrow-icon {
  position:absolute;
  right: 45%;
  bottom: 62%;
}
.borrow-header {
  color:#d6d6d6;
  position: absolute;
  font-size:20px;
  top: 40%;
  
}
.b-text-wrapper {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  position: relative;
  text-align: center;

}

.scanner-main {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: translateY(0);
  transition: transform 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  border: 1px solid black;
}
.scanner-main.hidden {
  /* display: none; */
  transform: translateY(100%);
}
.qr-scanner-wrapper {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  /* min-width: 30rem; */
  height: 75% !important;
  position: absolute;
  top: 15vh;
  bottom: 0px;
  left: 0px;
  right: 0px;
  flex-direction: column;
  background-color: #464646;
}
.scanner-wrapper {
  background-color: rgb(254, 255, 254);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.qr-scanner-placeholder {
  border-radius: 10px;
  height: 100% !important;
  width: 100% !important;
  /*background-color: red;*/
  color: black;
  /* z-index: 100; */
}
.qr-scanner-tag {
  width: 100%;
  height: 100%;
  display: flex;
  /*background-color: blue;*/
  justify-content: center;
  align-items: center;
}
.crosshair {
  position: absolute;
  background-image: url("../../public/scan_outline.svg");
  height: 400px;
  width: 400px;
  /* background-color: red; */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@keyframes example {
  from {
    bottom: -100px;
  }
  to {
    bottom: "100px",
  }
}
.slide-in-popup {
  left: 50% !important;
  animation-name: example;
  animation-duration: 225ms;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.slide-in-popup.animate {
  /* transition: left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms; */
}

.search-bar::placeholder {
  color: #c2c2c2 !important;
}
.search-bar {
  background-color: transparent !important;
  /* border-radius: 25px !important; */
  width: 90%;
  border: none !important;
}
.qr-search-container {
  background-color: #f6f8f5 !important;
  /* border: 2px grey solid !important; */
  border-radius: 20px;
}
.qr-search-container:focus-within {
  /* border-radius: 0px;   */
  /* border: 2px grey solid !important; */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.search-bar:focus {
  background-color: #d7d7d7;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.search-icon {
  border: none !important;
  background-color: transparent !important;
}
.search-button {
  margin-left: 5px !important;
  /* border-radius: 25px !important; */
  color: #68b49a !important;
  border: none !important;
  /* transform: ; */
  /* background-color: red !important; */
  /* width: 10%; */
}
.qr-scan-nav {
  background-color: white !important;
  color: black !important;
}
.qr-body-wrapper {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background-color: #464646;
  position: absolute;
  z-index: 0;
}
.notif {
  background-color: #f0f0f0;
  width: 330px;
  height: 80px;
  margin-left: calc(50% - 165px);
  padding: 16px;
  display: table;
  margin-bottom: 70px;
}
.graphic {
  float: left;
  background-color: #d6d6d6;
  width: 48px;
  height: 48px;
}
.text-group {
  float: left;
  margin-left: 15px;
  text-align: left;
  width: 200px;
  height: 34px;
}
.report-btn {
  float: left;
  width: 35px;
  height: 35px;
  margin-top: 6.5px;
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
/* i can't make an octagon */
.octagon {
  width: 20px;
  height: 20px;
  margin-left: -5.5px;
  background-color: #323232 !important;
}

.report-modal {
  font: Inter;
  margin-left: calc(50% - 124px) !important;
  width: 248px !important;
}
.report-modal-height {
  height: 366px !important;
}
.report-modal-header {
  font-size: 16px;
  font-weight: 600;
  background-color: #f0f0f0 !important;
  border-bottom: 0 !important;
  justify-content: center !important;
  padding-top: 15px !important;
  padding-bottom: 5px !important;
}
.report-modal-title {
  position: absolute;
  text-align: center !important;
  font-weight: 600px;
}
.report-modal-close {
  border: none !important;
  background-color: #f0f0f0 !important;
  color: black !important;
  margin-left: auto;
}
.report-modal-body {
  justify-content: center !important;
  background-color: #f0f0f0 !important;
  border: 0 !important;
  padding: 10px !important;
  font-size: 15px;
  font-weight: 400;
}
.large-graphic {
  margin-left: calc(50% - 49.5px);
  background-color: #d6d6d6;
  width: 99px;
  height: 99px;
}
.report-modal-footer {
  background-color: #f0f0f0 !important;
  border-top: 0 !important;
}
