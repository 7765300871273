:root {
  --light-blue: #E0F4F4;
  --turquoise: #68B49A;
  --dark-grey: #464646;
  --secondary-blue: #B0D1D8;
  --secondary-green: #29604D;
}
/* html, body, #root{
  height: 100%;
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
}

.sub-header-1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
}

.sub-header-2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.sub-header-3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.body {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.small-text-1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.small-text-2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.details-1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.details-2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.green {
  background-color: var(--green);
}

.light-blue {
  background-color: var(--light-blue);
}

.btn-primary {
  background-color: var(--turquoise) !important;
  color: white !important;
  border-radius: 50px !important;
  width: 150px !important;
  height: 40px !important;
}

.dish-card {
  box-shadow: 0px 7.86312px 15.7262px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 16px;
}

p::first-letter {
  text-transform:capitalize;
}

@media (max-width: 360px) {
  .sub-header-3 {
    font-size: 15px;
  }
}