:root {
    --bpdarkgrey: #333333;
    /* idk tbh */
    --darkgrey: #b7b7b7;
    --grey: #DDDDDD;
    --lightgrey: #f5f5f5;
    --modalborder: #c9c9c9;
}


.sub-header-2 {
    /*font-family: Inter;*/
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}


.body {
    /*font-family: Inter;*/
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}


/* .smallest {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
} */


.details {
    /*font-family: Inter;*/
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}


input[type="radio"] {
    width: 19px;
    height: 19px;
    accent-color: var(--bpdarkgrey);
    vertical-align: middle;
    margin-top: -4px;
}

.pagination {
    justify-content: center;
    margin-top: 24px;
}


.table-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #464646;
    width: 100%;
    height: 56px;
    padding: 16px;
    border: 0;
    color: white;
}

.search-container {
    border-radius: 30px;
    background-color: rgb(255, 255, 255);
    width: 206px;
    height: 40px;
    margin-right: 40px;
    border: 1px solid black;
    color: #C2C2C2;
    padding: 8px;
}


.search-b {
    border-radius: 30px;
    background-color: rgb(255, 255, 255);
    width: 138px;
    height: 40px;
    border: 2px solid #68B49A;
    color: #68B49A;
    padding: 8px;
    justify-content: space-around;
}

.row-container {
    width: 100%;
    height: 56px;
    padding: 16px;
    border: 0;
    color: black;
}


/* .tag-container {
    border-radius: 30px;
    background-color: rgb(255, 255, 255);
    height: 40px;
    border: 2px solid black;
    padding: 4px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
}

.tag-container2 {
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
    height: 30px;
    border: 2px solid black;
    padding: 2px;
    width: fit-content;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: center;

} */

/* .admin-btn {
    border-radius: 0;
    background-color: var(--darkgrey);
    width: 100%;
    border: 0;
    color: black;
    text-align: center;
}


.admin-btn:hover {
    background-color: var(--darkgrey);
    color: black;
}


.admin-btn:active {
    background-color: var(--darkgrey) !important;
    color: black !important;
}


.admin-btn:hover {
    background-color: darkgrey;
    color: black;
} */


.radio-label-group {
    margin: 8px 0 0 0;
}


.modal-title {
    height: 85px !important;
}


.qr-input {
    border: 0;
    padding: 5px;
    width: 100%;
}


/*
 * Sidebar
 */


.sidebar {
    background-color: #464646;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    /* Behind the navbar */
    padding: 0;
    /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}




@media (max-width: 767.98px) {
    .sidebar {
        top: 5rem;
    }
}


.sidebar-icon {
    height: 16px;
    padding-right: 10px;
}


.sidebar-sticky {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
    background: #464646;
    color: white
}


.sidebarNavLink {
    font-weight: 500;
    color: #333;
}


.sidebar-heading {
    font-size: small;
}


.nav-link {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: large;
}


a.nav-link:hover {
    color: white;
    background: #5E5E5E;
}
