/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 25px;
  height: 19.05px;
  left: 26px;
  top: 67px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #50a3a2;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: rgb(58, 58, 58);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  display: flex;
  flex-direction: column;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* ============= additional ================ */
.menu-item {
  font-size: 20px;
  color: white;
  margin-bottom: 30px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.menu-item:hover {
  color: #50a3a2;
}

.memu-title {
  color: white;
  font-size: 30px;
  font-weight: bold;
  margin: 10px 0 50px 0;
  text-align: center;
  /* text-decoration: underline;
  text-underline-offset: 0.5em; */
}



.logout {
  margin-top: auto;
  border-top: 3px solid #808080;
  padding-top: 15px;
}


@media (max-height: 740px) {
  .bm-burger-button {
    position: absolute;
    width: 25px;
    height: 19.05px;
    left: 26px;
    top: 8%;
  }
}